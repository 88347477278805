import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Grid } from '@mui/material';

import SynchedChartContainer from '../SynchedChartContainer';
import HistoricMainChart from './HistoricMainChart';
import HistoricVolumeChart from './HistoricVolumeChart';
import HistoricDeltaChart from './HistoricDeltaChart';

import { useQuery } from "@tanstack/react-query";
import { getUserMeta, setUserMeta } from "../../util/http";

import _ from 'lodash';

import Loading from "../Loading";
import Error from "../Error";
import { SAVE_KEY_HISTORICAL_DATE_INTERVAL, SAVE_KEY_HISTORICAL_METRICS } from '../../util/Constants';
import { HISTORIC_DELTA_CHART_SETTINGS_CONFIG, HISTORIC_DELTA_METRICS, HISTORIC_DELTA_TYPE, HISTORIC_MAIN_CHART_SETTINGS_CONFIG, HISTORIC_VOLUME_CHART_SETTINGS_CONFIG, HISTORIC_VOLUME_METRICS, HISTORIC_VOLUME_TYPE } from './HistoricSettingsConfig';
import NoTicker from '../ticker/NoTicker';
import { useDateInterval, useDispatchActionToCharts } from '../SynchedChartUtils';

const defaultMetrics = {
    main: {
        metrics: ['rank_v1'],
        type: 'candlestick'
    },
    volume: {
        type: HISTORIC_VOLUME_TYPE.C_VOL_P_VOL,
    },
    delta: {
        type: HISTORIC_DELTA_TYPE.C_DELTA_P_DELTA,
    },
};

export default function HistoricTab({ selectedTicker }) {

    //const chartRefs = [useRef(), useRef(), useRef()];
    const chart1 = useRef();
    const chart2 = useRef();
    const chart3 = useRef();
    const chartRefs = useMemo(() => [chart1, chart2, chart3], [chart1, chart2, chart3]);

    const dispatchActionToCharts = useDispatchActionToCharts(chartRefs);

    const memoizedDispacthFn0 = useCallback((action, payload) => dispatchActionToCharts(action, payload, chart1), [dispatchActionToCharts]);
    const memoizedDispacthFn1 = useCallback((action, payload) => dispatchActionToCharts(action, payload, chart2), [dispatchActionToCharts]);
    const memoizedDispacthFn2 = useCallback((action, payload) => dispatchActionToCharts(action, payload, chart3), [dispatchActionToCharts]);

    const [metrics, setMetrics] = useState(null/*defaultMetrics*/);
    const [isFullscreen, setFullscreen] = useState([false, false, false]);

    const { data: historicalDateInterval, isPending: isHistoricalDatePending, isFetching: isHistoricalDateFetching, isError: isHistoricalDateError, error: historicalDateError } = useQuery({
        //queryKey: [SAVE_KEY_HISTORICAL_DATE_INTERVAL, selectedTicker, ...isFullscreen],
        queryKey: ['user_meta', selectedTicker, ...isFullscreen],
        queryFn: () => getUserMeta(),
        select: (data) => data ? data[SAVE_KEY_HISTORICAL_DATE_INTERVAL]:null,
        enabled: !!selectedTicker,
        //initialData: defaultDateInterval,
    });

    const { data: historicalMetrics, isPending: isHistoricalMetricsPending, isFetching: isHistoricalMetricsFetching, isError: isHistoricalMetricsError, error: historicalMetricsError } = useQuery({
        //queryKey: [SAVE_KEY_HISTORICAL_METRICS, selectedTicker],
        queryKey: ['user_meta', selectedTicker, ...isFullscreen],
        queryFn: () => getUserMeta(),
        select: (data) => data ? data[SAVE_KEY_HISTORICAL_METRICS] : null,
        enabled: !!selectedTicker,
        //initialData: defaultMetrics,
    });

    //console.log(historicalDateInterval);    

    //console.log('historicalDateInterval: ', historicalDateInterval);
    const {startDate, endDate} = useDateInterval(historicalDateInterval);
    //console.log('endDate: ', endDate);

    useEffect(() => {
        if (!isHistoricalMetricsError && !isHistoricalMetricsPending && !isHistoricalMetricsFetching){
            if (process.env.NODE_ENV === 'development' || !!!historicalMetrics)
            {
                setMetrics(defaultMetrics);
            }
            else    
                setMetrics(historicalMetrics);
        }
    }, [historicalMetrics, isHistoricalMetricsError, isHistoricalMetricsFetching, isHistoricalMetricsPending, selectedTicker]);

    //console.log(historicData?.volumeData);
    const handleMetricsChanged = useCallback(({ selectedCheckboxes: indicators, selectedRadio: type }, chart) => {
        const newMetric = { metrics: indicators, type };
        if (!_.isEqual(metrics[chart], newMetric)) {
            setMetrics((prevMetrics) => {
                const nextMetrics = { ...prevMetrics, [chart]: newMetric };
                setUserMeta(SAVE_KEY_HISTORICAL_METRICS, nextMetrics);
                return nextMetrics;
            });
        }

    }, [metrics]);

    const saveHistoricInterval = useCallback((dateInterval) => { 
        setUserMeta(SAVE_KEY_HISTORICAL_DATE_INTERVAL, dateInterval);
    },[]);

    const sharedOption = useMemo(() => ({
        animation: false,
        manualZoom: false,
        tooltip: {
            trigger: 'axis',
            position: function (point, params, dom, rect, size) {
                const xOffset = 10;
                var chartWidth = size.viewSize[0];
                var chartHeight = size.viewSize[1];
                let x = point[0];
                let y = point[1];

                if (x + xOffset + dom.offsetWidth > chartWidth)
                    x -= xOffset + dom.offsetWidth;
                else
                    x += xOffset;

                if (y - dom.offsetHeight / 2 >= 0)
                    y -= dom.offsetHeight / 2;

                if (y + dom.offsetHeight > chartHeight)
                    y -= dom.offsetHeight;

                return [x, y];
            },
            // formatter: function(params) {
            //     let tooltipContent = `<div style="font-weight: bold">${params[0].name}</div>`; //`${params[0].name}\n`;
            //     const seriesIds = new Set();
            //     params.forEach(param => {
            //         if (!seriesIds.has(param.seriesId)) {
            //             tooltipContent += `<div>${param.marker} <span style="color:${param.color}">${param.seriesName}</span>: <span style="font-weight: bold">${param.value}</span></div>`;//`${param.marker} ${param.seriesName}: \u{1D400}${param.value}\n`;
            //             seriesIds.add(param.seriesId);
            //         }
            //     });
            //     return tooltipContent;
            // },            
        },
        dataZoom: [
            {
                type: "inside",
                xAxisIndex: 0,
                //start: 0,
                //end: 100,
                //startValue: startDate,
                //endValue: endDate,
                maxValueSpan: 2500,
                minValueSpan: 100,
                //moveOnMouseWheel : true,
                //maxSpan: 30,
            },
        ],

    }), []);

    if (!selectedTicker)
        return <NoTicker />;

    if (isHistoricalDatePending || isHistoricalDateFetching || isHistoricalMetricsFetching || isHistoricalMetricsPending || !metrics)
        return <Loading />;

    if (isHistoricalDateError)
        return <Error error={historicalDateError} />;

    if (isHistoricalMetricsError)
        return <Error error={historicalMetricsError} />;

    return (
        <Grid container spacing={1} sx={{ height: '100%', '& > *': { marginTop: '4px !important', marginBottom: '0px !important' } }}>
            <Grid item xs={12} sx={{ height: '80%' }}>
                <SynchedChartContainer
                    ChartComponent={HistoricMainChart}
                    ref={chartRefs[0]}
                    dispatchFn={memoizedDispacthFn0}
                    sharedOption={sharedOption}
                    //data={historicData.mainData}
                    ticker={selectedTicker}
                    settingsConfig={HISTORIC_MAIN_CHART_SETTINGS_CONFIG}
                    metrics={metrics.main.metrics}
                    type={metrics.main.type}
                    startDate={startDate}
                    endDate={endDate}
                    fullscreen={isFullscreen[0]}
                    handleFullscreenChart={(value) => setFullscreen([value, false, false])}
                    handleMetricsChanged={(selectedMetrics) => handleMetricsChanged(selectedMetrics, 'main')}
                    handleSaveInterval={saveHistoricInterval}
                //style={{height: '100%'}}//670 3.7
                />
            </Grid>
            <Grid item xs={6} sx={{ height: '19%' }}>
                <SynchedChartContainer
                    ChartComponent={HistoricVolumeChart}
                    ref={chartRefs[1]}
                    //dispatchFn={(action, payload) => dispatchActionToCharts(action, payload, 1)}
                    dispatchFn={memoizedDispacthFn1}
                    sharedOption={sharedOption}
                    //data={historicData.volumeData}
                    ticker={selectedTicker}
                    settingsConfig={HISTORIC_VOLUME_CHART_SETTINGS_CONFIG}
                    metrics={HISTORIC_VOLUME_METRICS[metrics.volume.type]}
                    type={metrics.volume.type}
                    startDate={startDate}
                    endDate={endDate}
                    fullscreen={isFullscreen[1]}
                    handleFullscreenChart={(value) => setFullscreen([false, value, false])}
                    handleMetricsChanged={(selectedMetrics) => handleMetricsChanged(selectedMetrics, 'volume')}
                    handleSaveInterval={saveHistoricInterval}
                //style={{height: '10%'}}//180
                />
            </Grid>
            <Grid item xs={6} sx={{ height: '19%' }}>
                <SynchedChartContainer
                    ChartComponent={HistoricDeltaChart}
                    ref={chartRefs[2]}
                    //dispatchFn={(action, payload) => dispatchActionToCharts(action, payload, 2)}
                    dispatchFn={memoizedDispacthFn2}
                    sharedOption={sharedOption}
                    //data={historicData.deltaData}
                    ticker={selectedTicker}
                    settingsConfig={HISTORIC_DELTA_CHART_SETTINGS_CONFIG}
                    metrics={HISTORIC_DELTA_METRICS[metrics.delta.type]}
                    type={metrics.delta.type}
                    startDate={startDate}
                    endDate={endDate}
                    fullscreen={isFullscreen[2]}
                    handleFullscreenChart={(value) => setFullscreen([false, false, value])}
                    handleMetricsChanged={(selectedMetrics) => handleMetricsChanged(selectedMetrics, 'delta')}
                    handleSaveInterval={saveHistoricInterval}
                //style={{height: '10%'}}
                />
            </Grid>
        </Grid>
    );
}