//import { useQuery } from "@tanstack/react-query";
//import { fetchHistoricChart } from "../util/http";

//import Loading from "./Loading";
//import Error from "./Error";
import { forwardRef, memo } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchHistoricChart } from "../../util/http";
import Loading from "../Loading";
import Error from "../Error";


import SynchedChart from "../SynchedChart";
import { Box } from "@mui/material";
import HistoricMeta from "./HistoricMeta";

// const arePropsEqual = (prevProps, nextProps) => {
//     console.log('here!');
//     let equal = true;
//     for (let key in prevProps) {
//       if (prevProps[key] !== nextProps[key]) {
//         equal = false;
//         console.log(`Prop '${key}' changed from '${prevProps[key]}' to '${nextProps[key]}'`);
//       }
//     }
//     return equal;
// }

let prevQueryKey = null;
async function fetchHistoricData(queryClient, ticker, metrics){
    const cachedQuery = prevQueryKey ? queryClient.getQueryData(prevQueryKey)?.data : null;
    let cachedData = {};            

    if (cachedQuery  && prevQueryKey.find((key) => key === ticker) !== undefined){
        cachedData['Price'] = cachedQuery['Price'];
        cachedData['Date'] = cachedQuery['Date'];
    }

    let extra_params = '';
    if (!cachedData?.Price){
        extra_params += '&price=close';
    }
    if (cachedData.Date){
        extra_params += '&exclude_date=true';
    }

    prevQueryKey = ['historicalChart', 'volume', ticker, [...metrics]];

    const data = await fetchHistoricChart(ticker, metrics, extra_params, null, null);

    if (cachedData){
        Object.assign(data.data, cachedData);
        //console.log(data.data);
    }

    return data;

}

const HistoricVolumeChart = memo(forwardRef(({ticker, metrics, type, sharedOption, startDate, endDate, ...props}, ref) =>{

    //const dataProviderRef = useRef(null);

    const queryClient = useQueryClient();        
    const { data: { data: chartData } = { data: [] }, isPending, isFetching, isError, error } = useQuery({
        queryKey: ['historicalChart', 'volume', ticker, [...metrics]/*, type*//*, startDate, endDate*/],
        queryFn: () => fetchHistoricData(queryClient, ticker, metrics),   
        refetchOnMount: false,
    });

    // useEffect( () => {
    //     if (ref.current) {       
    //         setTimeout( () => {
    //             console.log('volume is updating!');
    //             const dataProvider = new HistoricChartDataProvider(ref.current.getEchartsInstance());    
    //             dataProvider.init(ticker, metrics, '');
    //             dataProviderRef.current = dataProvider;    
    //         }, 100);
    //     }
    // }, [ref, dataProviderRef, metrics, ticker, type, isPending, isFetching, startDate, endDate]);


    if (isPending || isFetching)
        return <Loading />;

    if (isError)
        return <Error error={error} />;

    const startValue = chartData['Date'].findIndex((date) => date === startDate) !== -1 ? chartData['Date'].findIndex((date) => date === startDate) : chartData['Date'].length - 500;
    const endValue = chartData['Date'].findIndex((date) => date === endDate) !== -1 ? chartData['Date'].findIndex((date) => date === endDate) : chartData['Date'].length - 1;
    const numDataPoints = endValue - startValue;
    //console.log('volume num points: ', startValue, endValue);
    //console.log('volume date: ', startDate);
    //console.log('volume date index: ', chartData['Date'][startValue]);
    //console.log('metrics: ', metrics);
    const option = {   
        ...sharedOption,
        grid: {
            left: '10px', //'4%',
            right: '25px', //'12%',
            bottom: '25px',//'20%',
            top: '30px',//'25%' 
            containLabel: true,
        },
        legend: {
            data: Object.keys(chartData).filter(key => key !== 'Date' && key !== 'Price'),
            icon: 'circle',
            top: '8px',//'6%',
            left: 'center',
        },
        xAxis: 
        {
            type: 'category',
            data: chartData['Date'],
            boundaryGap: true,
            axisPointer: {
                type: "shadow",
                show: true,
                label: {show: true},
            },    
            axisTick: {
                show: false
            },
            axisLabel: {
                maxLabels: 15,
                interval: Math.round(numDataPoints / 15) - 1,
                rotate: 30,
                fontSize: 6,
            },
        },
        yAxis: [
            {
                position: 'right',
                type: 'value',
                splitLine: {
                    show: false,
                },
                axisLabel: {
                    formatter: function (value) {
                        //console.log(value);
                        if (value && value % 1000000 === 0)
                            return (value / 1000000) + 'M';
                        else if (value && value && value % 1000 === 0)
                            return (value / 1000) + 'K';
                        else
                            return value;
                    },
                    fontSize: 8,
                },                
            },
        ], 
        series: metrics.map((metric) => ({
            name: HistoricMeta.getInstance().getMetricDescription(metric),
            data: chartData[HistoricMeta.getInstance().getMetricDescription(metric)],
            type: 'bar',
            stack: 'Volume', 
            areaStyle: {},
            symbol: 'none',
            smooth: true,
        })), 
        dataZoom: [
            {
                ...sharedOption.dataZoom[0],                
                startValue,
                endValue,
            }
        ]
    };   



    return (
        <Box sx={{height:'100%'}}>
            <SynchedChart
                {...props}
                option={option}
                ref={ref}
                //dispatchFn={dispatchFn}
            />
        </Box>
    );    
})/*, arePropsEqual*/);

export default HistoricVolumeChart;