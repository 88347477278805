export function convertJsDateToServer(date){
    return date.toISOString().slice(0, 10).replace(/-/g, "");
}

export function convertServerDateToJs(date)
{
    return date.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3');
}

export function createDistinctColors(numColors) 
{
    const colors = [];
    const goldenRatioConjugate = 0.618033988749895;
    let hue = 0.3333333333; //green

    for (let i = 0; i < numColors; i++) 
    {
        const color = `hsl(${Math.floor(hue * 360)}, 80%, 70%)`;
        colors.push(color);

        hue += goldenRatioConjugate;
        hue %= 1;
    }

    return colors;
}


// export function prepareOverviewTableData(data, header, sortOn){
//     const result = {};

//     result.header = Object.values(header);

//     const keys = Object.keys(header);
//     result.body = [];
//     for (let index = 0; index < data[keys[0]].length; ++index){
//         result.body.push(keys.map((key) => data[key][index]));
//     }

//     //const sortIndex = keys.findIndex((key) => key === sortOn);
//     //result.body.sort((a, b) => b[sortIndex] - a[sortIndex]);

//     return result;
// }