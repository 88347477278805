import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography, IconButton } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { useEffect, useState } from "react";

import { useQuery } from "@tanstack/react-query";
import { fetchAssets, fetchAssetsDailyGains, getUserMeta, setUserMeta } from "../../util/http";

import Loading from "../Loading";
import Error from "../Error";
import { SAVE_KEY_FAVORITE_TICKERS } from "../../util/Constants";
import similarityMatchFilter from "../../util/SimiarityMatchFilter";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { StrictModeDroppable } from "../../util/StrictModeDroppable";
import { DragIndicator } from "@mui/icons-material";

const dev_favorite_tickers = [
    {id: 'SPY', selected: true, hovered: false, change: '-5.21%', price: '120.14'},
    {id: 'TSLA', selected: false, hovered: false, change: '+5.21%', price: '120.14'},
];

function selectTicker(favoriteTickers, index)
{
    const selectedTicker = favoriteTickers.find(item => item.selected)?.id;
    if (selectedTicker)
    {
        const prevSelected = favoriteTickers.find(item => item.id === selectedTicker);
        prevSelected.selected = false
    }
    favoriteTickers[index].selected = true;

    setUserMeta(SAVE_KEY_FAVORITE_TICKERS, favoriteTickers);
}

export default function TickerManager({callbackSelectTicker}){  

    const [favorite_tickers, setFavoriteTickers] = useState(null);

    const { data: loaded_favorite_tickers, isPending:isFavoriteTickersPending, isError: isFavoriteTickersError, error: favoriteTickersError } = useQuery({
        //queryKey: [SAVE_KEY_FAVORITE_TICKERS],
        queryKey: ['user_meta'],
        queryFn: () => getUserMeta(),
        select: (data) => data ? data[SAVE_KEY_FAVORITE_TICKERS] : null,
    });
        
    //console.log(favorite_tickers);
    const [isAddingTicker, setAddingTicker] = useState(false);

    const {data: { data: { data: assetGains } = {} } = {}, isPending: isAssetGainsPending, isError: isAssetGainsError, error: assetGainsError} = useQuery({
        queryKey: ['assets', ...(favorite_tickers ? favorite_tickers.map((ticker) => ticker.id) : [])],
        queryFn: () => fetchAssetsDailyGains(favorite_tickers ? favorite_tickers.map((ticker) => ticker.id) : []),
        enabled: !isFavoriteTickersPending && !isFavoriteTickersError && favorite_tickers != null,
    });    


    const {data: { data: { assets: assetList } = {} } = {}, isPending: isAssetsPending, isError: isAssetsError, error: assetsError} = useQuery({
        queryKey: ['assets list'],
        queryFn: () => fetchAssets(),
        staleTime: Infinity,
    });

    useEffect(() => {
        //console.log('use effect load ', loaded_favorite_tickers);
        if (!isFavoriteTickersPending && !isFavoriteTickersError && !favorite_tickers)
        {
            //console.log('loaded tickers', loaded_favorite_tickers);
            if (process.env.NODE_ENV === 'development'){
                setFavoriteTickers(dev_favorite_tickers);
            }
            else{               
                setFavoriteTickers(loaded_favorite_tickers ? loaded_favorite_tickers : []);
            }
        }
        const selectedTicker = favorite_tickers?.find(item => item.selected)?.id;
        callbackSelectTicker(selectedTicker);
    }, [isFavoriteTickersPending, isFavoriteTickersError, loaded_favorite_tickers, callbackSelectTicker, favorite_tickers]);

    useEffect(() => {
        if (assetGains && loaded_favorite_tickers && !isAssetGainsPending && !isAssetGainsError){                        
            setFavoriteTickers((prevFavoriteTickers) => {
                return prevFavoriteTickers?.map((ticker) => {
                    const assetGain = assetGains.find((assetGain) => assetGain.Asset === ticker.id);
                    return {
                        ...ticker,
                        change: assetGain.Change > 0 ? `+${assetGain.Change}%` : `${assetGain.Change}%`,
                        price: assetGain.Price,
                    }
                })
            });
        }
    }, [loaded_favorite_tickers, assetGains, isAssetGainsPending, isAssetGainsError]);

    if (isFavoriteTickersPending || isAssetGainsPending)
    {
        return (
            <Box border="1px solid #ccc" borderRadius={4} sx={{ minHeight: '100%', minWidth: '100%', display: 'flex', justifyContent:'center', direction:'column'}}>
                <p>Loading tickers...</p>
            </Box>
        );
    }

    if (isFavoriteTickersError)
    {
        return  <Error error={favoriteTickersError} />;
    }

    if (isAssetGainsError)
    {
        return  <Error error={assetGainsError} />;
    }    

    function handleMouseHoverTicker(index, mouseEnter)
    {        
        setFavoriteTickers((prevFavoriteTickers) => {
            const newFavoriteTickers = [...prevFavoriteTickers];
            newFavoriteTickers[index].hovered = mouseEnter;
            return newFavoriteTickers;
        });
    }

    function handleAddTicker(event, value)
    {
        setAddingTicker(false);

        if (value){
            setFavoriteTickers(prevFavoriteTickers => {
                const newFavoriteTickers = [...prevFavoriteTickers, {
                    id: value,
                    selected: false,
                    hovered: false,
                }];

                selectTicker(newFavoriteTickers, newFavoriteTickers.length - 1);    
                
                return newFavoriteTickers;
            })
        }
    }

    function handleCancelAddTicker(){
        setAddingTicker(false);
    }

    function handleRemoveTicker(event, index)
    {
        event.stopPropagation();
        setFavoriteTickers((prevFavoriteTickers) => {
            const newFavoriteTickers = [...prevFavoriteTickers];
            newFavoriteTickers.splice(index, 1);

            setUserMeta(SAVE_KEY_FAVORITE_TICKERS, newFavoriteTickers);

            return newFavoriteTickers;
        });
    }

    function handleSelectTicker(index)
    {
        //console.log('select');
        //console.log('selectedTicker', selectedTicker);
        if (!favorite_tickers[index].selected)
        {
            setFavoriteTickers((prevFavoriteTickers) => {
                const newFavoriteTickers = [...prevFavoriteTickers];

                selectTicker(newFavoriteTickers, index);                        
                callbackSelectTicker(newFavoriteTickers[index].id);
                return newFavoriteTickers;
            });
        }
    }

    function handleOnDragEnd(result)
    {
        //console.log('drag end ', result);
        if (!result.destination) return;
        if (result.destination.index === result.source.index) return;

        setFavoriteTickers((prevFavoriteTickers) => {
            const newFavoriteTickers = Array.from(prevFavoriteTickers);
            const [movedTicker] = newFavoriteTickers.splice(result.source.index, 1);
            newFavoriteTickers.splice(result.destination.index, 0, movedTicker);

            setUserMeta(SAVE_KEY_FAVORITE_TICKERS, newFavoriteTickers);

            return newFavoriteTickers;
        });        
    }

    return (
        <Box border="1px solid #ccc" borderRadius={4} style={{ height: '100%', width: '100%', maxHeight: '94vh', overflowY:'auto', overflowX: 'hidden'}}>
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Table sx={{margin:'0px !important'}}>
                    <TableHead style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor:  'black' }}>
                        <TableRow 
                            sx={{ '& > *': { padding: '2px !important', minHeight: '100px !important', textTransform: 'uppercase'} }}                        
                        >
                            <TableCell width='5%' align='center'><Typography variant="body1"></Typography></TableCell>
                            <TableCell widt='30%' align='center'><Typography variant="body1">Ticker</Typography></TableCell>
                            <TableCell width='30%' align='center'><Typography variant="body1">Last</Typography></TableCell>
                            <TableCell width='30%' align='center'><Typography variant="body1">Chg%</Typography></TableCell>
                            <TableCell width='5%' align='center' sx={{minWidth: 32, maxWidth: 32}}>
                                <Box sx={{ minHeight: '40px' }}>
                                    <IconButton aria-label="add" onClick={() => setAddingTicker(!isAddingTicker)}><AddIcon /></IconButton>
                                </Box>
                                </TableCell>
                        </TableRow>
                    </TableHead>
                    <StrictModeDroppable droppableId="droppable-rows">
                        {(provided) => (
                            <TableBody {...provided.droppableProps} ref={provided.innerRef}>                    
                                {favorite_tickers?.map((ticker, index) => (
                                    <Draggable key={ticker.id} draggableId={ticker.id} index={index}>
                                        {(provided) => (
                                            <TableRow 
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                //{...provided.dragHandleProps}
                                                //key={index} 
                                                sx={{ 
                                                    '& > *': { flexGrow: 1, padding: '2px !important',},
                                                    '&:hover': {
                                                        cursor: 'pointer',
                                                        backgroundColor: 'rgba(211, 211, 211, 0.5)',
                                                    }, 
                                                    backgroundColor: ticker.selected ? 'rgba(211, 211, 211, 0.5)':null,                             
                                                }}
                                                onMouseEnter={() => handleMouseHoverTicker(index, true)} 
                                                onMouseLeave={() => handleMouseHoverTicker(index, false)}  
                                                onClick={() => handleSelectTicker(index)}               
                                            >
                                                 <TableCell {...provided.dragHandleProps} sx={{ cursor: 'grab'}}>
                                                 <DragIndicator
                                                    fontSize="small"
                                                    sx={{
                                                        visibility: ticker.hovered ? 'visible' : 'hidden'
                                                    }}
                                                />                                                
                                                </TableCell>
                                                <TableCell align='center'><Typography variant="body1">{ticker.id}</Typography></TableCell>
                                                <TableCell align='center'><Typography variant="body1">{ticker.price}</Typography></TableCell>
                                                <TableCell align='center'><Typography variant="body1" color={ticker.change?.startsWith('+') ? "#00FF00" : ticker.change?.startsWith('-') ? "#FF0000" : "inherit" }>{ticker.change}</Typography></TableCell>
                                                <TableCell align='center' sx={{minWidth: 32, maxWidth: 32}}>
                                                    <IconButton 
                                                        sx={{
                                                            visibility: ticker.hovered ? 'visible' : 'hidden'
                                                        }}
                                                        aria-label="add" 
                                                        onClick={(event) => handleRemoveTicker(event, index)}>
                                                            <RemoveIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </Draggable>                                            

                                ))}
                                {provided.placeholder}
                            </TableBody>  
                        )}
                    </StrictModeDroppable>              
                </Table>
            </DragDropContext>
            {
                isAddingTicker && (
                    isAssetsPending ? <Loading /> :
                    isAssetsError ? <Error error={assetsError} /> :
                    !isAssetsPending && !isAssetsError && (
                        <Autocomplete
                            options={assetList}
                            onChange={handleAddTicker}
                            onBlur={handleCancelAddTicker}
                            renderInput={(params) => <TextField {...params} label="Search" autoFocus={isAddingTicker} variant="outlined" />}
                            filterOptions={(options, { inputValue }) => similarityMatchFilter(options, { inputValue, excludedOptions : (favorite_tickers || []).map((ticker) => ticker.id) })}
                        />
                    )
                )
            }            
        </Box>
    );
}