import React, { forwardRef, memo, useCallback, useState } from 'react';
import { IconButton, Box } from '@mui/material';
import { Fullscreen, Settings } from '@mui/icons-material';
import SettingsDialog from './historic/SettingsDialog';

import { ChartActions } from "./EChartTheme";


const SynchedChartContainer = memo(forwardRef(({ ChartComponent, dispatchFn, data, sharedOption, settingsConfig, metrics, type, fullscreen, handleMetricsChanged, handleFullscreenChart, ...props }, ref) => {
  const [isHovering, setHovering] = useState(false);
  const [isSettingsOpen, setSettingOpen] = useState(false);
  //const [isFullscreen, setFullscreen] = useState(false);

  function handleMouseEnter(event) {
    if (event.buttons === 0) {    
      setHovering(true);
      dispatchFn(ChartActions.TOOLTIP_ON_ENTER, null);
    }
  }

  function handleMouseLeave(event) {
    if (event.buttons === 0) {
      setHovering(false);
      dispatchFn(ChartActions.TOOLTIP_ON_LEAVE, null);
    }
  }

  const handleSettingsCancel = useCallback(() => {
    setSettingOpen(false);
  }, [setSettingOpen]);

  const handleSettingsSubmit = useCallback((selectedMetrics) => {
    setSettingOpen(false);
    handleMetricsChanged(selectedMetrics);
  }, [setSettingOpen, handleMetricsChanged]);

  const handleCloseFullscreen = useCallback(() => {
      handleFullscreenChart(false);
  }, [handleFullscreenChart]);

  const handleFullScreenSettingsOpen = useCallback(() => setSettingOpen(true), [setSettingOpen]);

  const handleOpenFullscreen = useCallback(() => {
    handleFullscreenChart(true);
  }, [handleFullscreenChart]);

  return (
     <Box 
      sx={{height: '100%', padding: '0px !important'}} 
      position='relative' 
      border="1px solid #ccc" 
      borderRadius={4} 
      onMouseEnter={(event) => handleMouseEnter(event)} 
      onMouseLeave={(event) => handleMouseLeave(event)} 
    >
      {isHovering &&
        <Box position='absolute' top={0} right={0} zIndex={2000} >
          {settingsConfig &&
            <IconButton onClick={() => setSettingOpen(true)}>
              <Settings/>
            </IconButton>
          }
          <IconButton onClick={handleOpenFullscreen}>
            <Fullscreen />
          </IconButton>
        </Box>
      }
      <Box 
        sx={{height:'100%', padding: '0px !important'}}
      >
        <ChartComponent
          {...props}          
          //hideTooltipContent={!isHovering}
          dispatchFn={dispatchFn}
          ref={ref}
          sharedOption={sharedOption}
          data={data}
          //style={{height: '100%'}}
          metrics={metrics}
          type={type}
          fullscreen={fullscreen}
          handleCloseFullscreen={handleCloseFullscreen}
          handleFullsceenSettings={() => settingsConfig ? handleFullScreenSettingsOpen() : null}
        />
      </Box>
      {isSettingsOpen && 
        <SettingsDialog
        open={isSettingsOpen}
        handleCancel={handleSettingsCancel}
        handleSubmit={handleSettingsSubmit}
        config={settingsConfig}
        checkboxOptions={metrics}
        radioOption={type}
        />
      }
    </Box>
  );
}));


export default SynchedChartContainer;