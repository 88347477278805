import DateManager from "../DateManager";
import { Box, Grid } from "@mui/material";
import PositioningRating from "./PositioningRating";
import { useQuery } from "@tanstack/react-query";
import { fetchOverviewData } from "../../util/http";
import Loading from "../Loading";
import Error from "../Error";
import NoTicker from "../ticker/NoTicker";
import OverviewDeltaChart from "./OverviewDeltaChart";
import OverviewTable from "./OverviewTable";
import DailyMeta from "../daily/DailyMeta";

export default function OverviewTab({ selectedTicker, selectedDate, handleSetDate }) {

    const queryDate = selectedDate === 'latest' ? DailyMeta.getInstance().getLatest() : selectedDate;
    const { data: { data: overviewData, metadata: overviewMeta } = { data: [], metadata: [] }, isPending, isError, error } = useQuery({
        queryKey: ['overview', selectedTicker, queryDate],
        queryFn: () => fetchOverviewData(selectedTicker, queryDate),
        enabled: selectedTicker !== null && selectedTicker !== undefined,
    });

        const oiTableData = (overviewData && overviewData['Open Interest Changes']) ? overviewData['Open Interest Changes']  : null;
        const volumeTableData = (overviewData && overviewData['Volume']) ?  overviewData['Volume'] : null;

    //console.log(volumeTableData);


    if (!selectedTicker)
        return <NoTicker />;    

    if (isPending)
        return <Loading />;

    if (isError) {
        return <Error error={error} />;
    }

    return (
        <Grid container rowSpacing={2} columnSpacing={2} /*sx={{  '& > *': { border: '3px solid white' } }}*/>
            <Grid item xs={6} sx={{ pointerEvents: 'none', height: '6vh', marginTop: '-48px', marginBottom: '0px' }} />
            <Grid item xs={6} sx={{ height: '6vh', marginTop: '-48px', marginBottom: '0px' }}>
                <DateManager
                    date={selectedDate}
                    prevDate={overviewMeta.previous}
                    nextDate={overviewMeta.next}
                    minDate='01-01-2007'
                    handleSetDate={handleSetDate}
                />
            </Grid>
            {overviewData && (
                <>
                    <Grid item
                        xs={6}
                        style={{ height: '45vh', marginBottom: '20px' }}
                    >
                        <PositioningRating
                            data={overviewData.positioning}
                        />
                    </Grid>
                    <Grid item
                        xs={6}
                        style={{ height: '45vh', marginBottom: '20px' }}
                    >
                        <OverviewDeltaChart
                            data={overviewData.forward_delta}
                        />
                    </Grid>
                    <Grid item
                        xs={6}
                        style={{ height: '45vh', marginBottom: '20px' }}
                    >
                        <OverviewTable
                            title='Open Interest Changes'
                            data={oiTableData}
                            colorHandle={(data, index) => {
                                if (index === 0) 
                                    return data[index] === 'CALL' ? '#00FF00' : '#FF0000';
                                if (index === data.length - 1) 
                                    return data[index] >= 0 ? '#00FF00' : '#FF0000';

                                return 'inherit';
                            }}                             
                        />
                    </Grid>
                    <Grid item
                        xs={6}
                        style={{ height: '45vh', marginBottom: '20px' }}
                    >
                        <OverviewTable
                            title='Volume'
                            data={volumeTableData}
                            colorHandle={(data, index) => {
                                if (index === 0) 
                                    return data[index] === 'CALL' ? '#00FF00' : '#FF0000';
                                if (index === data.length - 2) 
                                    return data[index].length >= 4 ? '#87CEEB' : 'inherit';

                                return 'inherit';
                            }}                             
                        />
                    </Grid>                    
                </>
            )}
            {!overviewData && (
                <Box border="1px solid #ccc" borderRadius={4} sx={{ minHeight: '100%', minWidth: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', direction: 'column' }}>
                    <p>No data available for this date.</p>
                </Box>
            )}

        </Grid>
    );
}