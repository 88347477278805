import { Box, Typography } from "@mui/material";
import styled from "styled-components";

const BarContainer = styled(Box)(() => ({
    position: 'relative',
    width: '100%',
    height: 12,
    marginBottom: 30,
    //border: '3px solid red',
    backgroundColor: '#8C7B94', 
  }));

  const ProgressBar = styled(Box)(({ theme }) => ({
    position: 'absolute',
    height: '100%',
    backgroundColor: '#B3D4F0', 
  }));
  

  const Marker = styled(Box)(() => ({
    position: 'absolute',
    width: 0,
    height: 0,
    borderLeft: '8px solid transparent',
    borderRight: '8px solid transparent',
    borderBottom: '16px solid #005DAD',
  }));

export default function RangeMarkers({title, markers}){
    const progressBarStart = `${markers.length > 1 ? markers[0] : 0}%`;
    const progressBarWidth = `${markers.length > 1 ? markers[1] - markers[0] : markers[0]}%`;

    return (
        <Box width="100%" padding={2}>
            <Typography textAlign='center'>{title}</Typography>
            <BarContainer>
                <ProgressBar style={{ left: progressBarStart, width: progressBarWidth }} />
                {markers.map((marker, index) => ( 
                    <Box
                        key={index}
                        position="absolute"
                        display="flex"
                        alignItems="center"
                        justifyContent= 'center'
                        style={{ left: `${marker}%`, transform: 'translateX(-50%)', bottom: -10, border: '2px solid red' }}
                    >   
                        <Marker
                        />
                         <Typography variant="caption" sx={{position: 'absolute', left: marker >= 20 ? '-12px' : '12px',  transform:  marker >= 20 ? 'translateX(-100%)' : '', bottom: '-13px' }}>{`${marker}%`}</Typography>
                    </Box>
                ))}
            </BarContainer>
        </Box>
    );

}