export default function similarityMatchFilter(options, { inputValue, excludedOptions = [] }){
    // if (!inputValue.length)
    //     return [];
    const filteredOptions = options.filter(option =>
        option.toLowerCase().includes(inputValue.toLowerCase()) &&
        !excludedOptions.includes(option)
    );

    const calculateSimilarity = (str1, str2) => {
        const length = Math.max(str1.length, str2.length);
        let commonChars = 0;
        for (let i = 0; i < length; i++) {
            if (str1[i] && str2[i] && str1[i].toLowerCase() === str2[i].toLowerCase()) {
                commonChars++;
            }
        }
        return commonChars / length;
    };

    return filteredOptions.sort((optionA, optionB) => {
        const similarityA = calculateSimilarity(optionA, inputValue);
        const similarityB = calculateSimilarity(optionB, inputValue);
        return similarityB - similarityA;
    });
};
