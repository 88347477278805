import { debounce, throttle } from "lodash";
import { ChartActions } from "./EChartTheme";
import { useCallback, useMemo } from "react";
//import DailyMeta from '../components/daily/DailyMeta.js';
import HistoricMeta from "./historic/HistoricMeta.js";

function dispatchZoom(echartsInstance, zoom, dispatchFn){
    if (dispatchFn)
        dispatchFn(ChartActions.DATA_ZOOM, zoom);
    //console.log(zoom);
    updateAxisLabel(echartsInstance, zoom);                                      
}

const last500days = new Date();
last500days.setDate(last500days.getDate() - 500);

const defaultDateInterval = {
    start: last500days.toISOString().slice(0, 10),
    end: (new Date()).toISOString().slice(0, 10),
};

export function useDateInterval(interval)
{
    interval = latestToDateInterval(interval);
    const startDate = useMemo(() => {
        if (interval?.start) {
            return new Date(interval.start).toISOString().slice(0, 10);
        }
        return '';
    }, [interval]);

    const endDate = useMemo(() => {
        if (interval?.end) {
            return new Date(interval.end).toISOString().slice(0, 10);
        }
        return '';
    }, [interval]);

    if (process.env.NODE_ENV === 'development' || startDate === '') {
        const fallbackStartDate = new Date(defaultDateInterval.start).toISOString().slice(0, 10);
        const fallbackEndDate = new Date(defaultDateInterval.end).toISOString().slice(0, 10);
        return { startDate: fallbackStartDate, endDate: fallbackEndDate };
    }

    return { startDate, endDate };    
}

function dateIntervalToLatest(zoom)
{
    if (zoom.end === HistoricMeta.getInstance().getLatestJs())
        zoom.end = 'latest';

    return zoom;
};

function latestToDateInterval(interval)
{
    if (!interval) return interval;

    if (interval.end === 'latest')
        interval.end = HistoricMeta.getInstance().getLatestJs();

    return interval;
};

const saveZoomHandler = debounce((dateInterval, handleSaveInterval) => {
    handleSaveInterval(dateIntervalToLatest(dateInterval));
  }, 500);



function updateAxisLabel(echartsInstance, zoom){
    const option = echartsInstance.getOption(); 
    //console.log(option.xAxis[0].axisLabel);
    //console.log(event);
    const numDataPoints = zoom.endValue - zoom.startValue;
    option.xAxis[0].axisLabel.interval = Math.round(numDataPoints / option.xAxis[0].axisLabel.maxLabels) - 1;
    echartsInstance.setOption(option);
}

export default function onChartReady(echartsInstance, dispatchFn, handleSaveInterval){
    const zr = echartsInstance.getZr();

    let lastZoom = {
        startValue: echartsInstance.getOption().dataZoom[0].startValue,
        endValue: echartsInstance.getOption().dataZoom[0].endValue,
    };

    echartsInstance.on('datazoom',throttle((event) => { 
        if (event.batch && event.batch[0]) {
            const dataZoom = echartsInstance.getOption().dataZoom[0];  
            const span = dataZoom.endValue - dataZoom.startValue;

            //console.log('span: ', span);
            //console.log('diff: ', lastZoom.endValue - lastZoom.startValue);
            if (span !== lastZoom.endValue - lastZoom.startValue)
            {
                //console.log('try to zoom to end ', span);
                const newZoom = {
                    startValue: lastZoom.endValue - span,
                    endValue: lastZoom.endValue,
                    //zoomOnMouseWheel: (span > dataZoom.minValueSpan && span < dataZoom.maxValueSpan),
                };                            
                echartsInstance.setOption({
                    dataZoom: {
                        type: 'inside',
                        ...newZoom,
                    }
                });
                //console.log('newZoom', newZoom);
                dispatchZoom(echartsInstance, newZoom, dispatchFn);
                lastZoom = newZoom;                    
            }
            else {
                dispatchZoom(echartsInstance, dataZoom, dispatchFn);
                lastZoom = dataZoom;                    
            }

            //if (echartsInstance.getOption().manualZoom)
            //    echartsInstance.setOption({manualZoom: false});

            const xAxisData = echartsInstance.getOption().xAxis[0].data;
            
            if (handleSaveInterval){
                saveZoomHandler({
                    start: xAxisData[echartsInstance.getOption().dataZoom[0].startValue],
                    end: xAxisData[echartsInstance.getOption().dataZoom[0].endValue]
                }, handleSaveInterval);
            }
            //console.log('option: ', xAxisData[echartsInstance.getOption().dataZoom[0].startValue]);                        
        }
        else{
            updateAxisLabel(echartsInstance, {
                startValue: event.startValue,
                endValue: event.endValue,
            });
        }
    }, 10));

    const throttledMouseMove = throttle((event) => {
        if (event.event.buttons === 0) {
            const x = event.offsetX;
            const xValue = echartsInstance.convertFromPixel({ xAxisIndex: 0 }, x);
            const date = echartsInstance.getOption().xAxis[0].data[xValue];
            //console.log('mouse move: ', date);
            //console.log("move x: ", x, " xvalue: ", xValue);
            if (dispatchFn)
                dispatchFn(ChartActions.TOOLTIP_MOVE, { date });
        }
    }, 50);
    zr.on('mousemove', throttledMouseMove);

    const throttledMouseWheel = throttle((event) => {
        const dataZoom = echartsInstance.getOption().dataZoom[0];  
        const span = dataZoom.endValue - dataZoom.startValue; 
        //console.log(event);
        dataZoom.zoomOnMouseWheel = ((span > dataZoom.minValueSpan || event.wheelDelta < 0) && 
            (span < dataZoom.maxValueSpan || event.wheelDelta > 0));
        //console.log('zoom on: ', dataZoom.zoomOnMouseWheel);
        echartsInstance.setOption({dataZoom});
    }, 50);
    zr.on('mousewheel', throttledMouseWheel);
}

export function useDispatchActionToCharts(chartRefs){
    return useCallback(((action, payload, senderRef) => {

        chartRefs.forEach((ref, index) => {
            if (ref.current !== senderRef.current && ref.current) {
                const chartInstance = ref.current.getEchartsInstance();
                //console.log('chart: ', index, chartInstance);
                //console.log(chartInstance);          

                switch (action) {
                    case ChartActions.TOOLTIP_MOVE: {
                        const xValue = chartInstance.getOption().xAxis[0].data.findIndex((date) => date === payload.date);
                        const pixelX = chartInstance.convertToPixel({ xAxisIndex: 0 }, xValue);

                        const chartHeight = chartInstance.getDom().offsetHeight
                        //console.log('chart height: ', chartHeight);
                        chartInstance.dispatchAction({
                            type: 'showTip',
                            //seriesIndex: 0,
                            //dataIndex: payload.xValue,
                            x: pixelX,
                            y: chartHeight / 2,//70,
                        });
                        /*
                        chartInstance.dispatchAction({
                            type: 'updateAxisPointer',
                            dataIndex: payload.xValue,
                          });
                        */
                        break;
                    }
                    case ChartActions.TOOLTIP_ON_ENTER: {
                        //console.log('onenter');

                        //const hideTooltip = debounce( () => {
                        //setTimeout( () => {
                        //    chartInstance.setOption({tooltip: {showContent: false}});
                        //}, 100);
                        //}, 200);
                        //hideTooltip();

                        break;
                    }
                    case ChartActions.TOOLTIP_ON_LEAVE: {
                        //console.log('show');  

                        // chartInstance.dispatchAction({
                        //     type: 'hideTip',
                        // });
                        // chartInstance.dispatchAction({
                        //     type: 'updateAxisPointer',
                        //   });

                        // chartInstance.setOption({tooltip: {showContent: true}});

                        break;
                    }
                    case ChartActions.DATA_ZOOM: {
                        chartInstance.dispatchAction({
                            type: 'dataZoom',
                            dataZoomIndex: 0,
                            //start: payload.start,
                            //end: payload.end,
                            startValue: payload.startValue,
                            endValue: payload.endValue,
                        });
                        //const throttledResize = throttle(() => {
                        //chartInstance.resize();
                        //}, 50);
                        //throttledResize();

                        break;
                    }
                    default: {
                        console.log('error!');
                    }
                }
            }

        });
    }), [chartRefs]);    
}