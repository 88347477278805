import React, { useCallback, useEffect } from 'react';
import { Grid, Paper, Tabs, Tab, Box } from '@mui/material';
import HistoricTab from '../components/historic/HistoricTab';
//import GridChart from '../components/GridChart';

import TickerManager from '../components/ticker/TickerManager';
import PositioningTab from '../components/daily/PositioningTab.jsx';
import HistoricMeta from '../components/historic/HistoricMeta.js';
import DailyMeta from '../components/daily/DailyMeta.js';
import { useQuery } from '@tanstack/react-query';
import { fetchDailyMeta, fetchHistoricMeta, getUserMeta, setUserMeta } from '../util/http.js';
import Loading from '../components/Loading.jsx';
import Error from '../components/Error.jsx';
import VolumeTab from '../components/daily/VolumeTab.jsx';
import OverviewTab from '../components/overview/OverviewTab.jsx';
import { SAVE_KEY_CHARTS_PAGE_TAB, SAVE_KEY_DAILY_DATE } from '../util/Constants.js';


export default function ChartsPage(){ 

  async function getDailyMeta()
  {
    const data = await fetchDailyMeta();
    DailyMeta.getInstance().setData(data);

    return data;
  }

  async function getHistoricMeta()
  {
    const data = await fetchHistoricMeta();
    HistoricMeta.getInstance().setData(data);

    return data;
  }

  //const isWPNoncePresent = !isWPNoncePending && !isWPNonceError;

  const { isPending: isDailyMetaPending, isError: isDailyMetaError, error: dailyMetaError } = useQuery({
    queryKey: ['daily meta'],
    queryFn: () => getDailyMeta(),   
  });

  const { isPending: isHistoricMetaPending, isError: isHistoricMetaError, error: historicMetaError } = useQuery({
    queryKey: ['historic meta'],
    queryFn: () => getHistoricMeta(), 
  });

  const { data: loadedDailyDate, isPending: isDailyDatePending, isError: isDailyDateError, error: dailyDateError } = useQuery({
    //queryKey: ['savedDate'],
    queryKey: ['user_meta'],
    queryFn: () => getUserMeta(),
    select: (data) => data ? data[SAVE_KEY_DAILY_DATE] : null
});

const { data: loadedTab, isPending: isLoadingTabPending, isError: isLoadingTabError, error: loadingTabError } = useQuery({
  //queryKey: ['savedTab'],
  queryKey: ['user_meta'],
  queryFn: () => getUserMeta(),
  select: (data) => data ? data[SAVE_KEY_CHARTS_PAGE_TAB] : null
});


  const [selectedTicker, setSelectedTicker] = React.useState(null);
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [selectedDate, setSelectedDate] = React.useState(null/*'latest'*/);
  const [dailyPrice, setDailyPrice] = React.useState(null);

  useEffect(() => {
    if (!isDailyDatePending && !isDailyDateError) {
        if (process.env.NODE_ENV === 'development') {
            setSelectedDate('latest');
        }
        else {
            if (!loadedDailyDate)
                setSelectedDate('latest');
            else
                setSelectedDate(loadedDailyDate);
        }
    }
}, [isDailyDatePending, isDailyDateError, loadedDailyDate]);

useEffect(() => {
  if (!isLoadingTabPending && !isLoadingTabError)
  {
    setSelectedTab(process.env.NODE_ENV === 'development' ? 0 : (loadedTab ? loadedTab : 0));
  }
},[isLoadingTabPending, isLoadingTabError, loadedTab]);


  const handleTabChange = (event, newValue) => {
    if (newValue !== selectedTab)
    {
      setUserMeta(SAVE_KEY_CHARTS_PAGE_TAB, newValue);
      setSelectedTab(newValue);
    }
  };

  const handleSetTicker = useCallback((id) => {
    if (id !== selectedTicker){
      setSelectedTicker(id);
      setDailyPrice(null);
    }
  }, [selectedTicker, setSelectedTicker, setDailyPrice]);

const handleSetDate = useCallback((date) => {
  //if (date === new Date().toISOString().slice(0, 10).replace(/-/g, ''))
  if (date === DailyMeta.getInstance().getLatest())
      date = 'latest';
  setSelectedDate(date);
  setUserMeta(SAVE_KEY_DAILY_DATE, date);
}, []);

const handleSetPrice = useCallback((price) => {
  setDailyPrice(price);
}, []);


if (isDailyMetaPending || isHistoricMetaPending || isDailyDatePending || isLoadingTabPending || !selectedDate)
    return <Loading/>;


  if (isDailyMetaError)
    return <Error error={dailyMetaError} />;

  if (isHistoricMetaError)
    return <Error error={historicMetaError} />;

  if (isDailyDateError)
    return <Error error={dailyDateError} />;

  if (isLoadingTabError)
    return <Error error={loadingTabError} />;
  

  return (
    <Grid container spacing={1} columns={18}>
      <Grid item xs={3}>
        <Paper style={{ height: '100%', width:'100%'}}>
          <TickerManager
            callbackSelectTicker={handleSetTicker
          }/>
        </Paper>
      </Grid>

      <Grid item xs={15} sx={{minHeight:'95vh'}}>
        <Tabs  value={selectedTab} onChange={handleTabChange} centered={false} >
          <Tab label="Overview" />
          <Tab label="Historic" />
          <Tab label="Positioning" />
          <Tab label="Volume" />
        </Tabs>        
        {selectedTab === 0 && (
          <Box sx={{height:'97%'}}>
            <OverviewTab 
              selectedTicker={selectedTicker}
              selectedDate={selectedDate}
              handleSetDate={handleSetDate}
            />
          </Box>
        )}
        {selectedTab === 1 && (
          <Box sx={{height:'97%'}}>
            <HistoricTab selectedTicker={selectedTicker}/>
          </Box>
        )}
        {selectedTab === 2 && (
          <Box sx={{height:'97%'}}>
            <PositioningTab 
              selectedTicker={selectedTicker} 
              selectedDate={selectedDate}
              handleSetDate={handleSetDate}
              handleSetPrice={handleSetPrice}
              price={dailyPrice}
            />
          </Box>
        )}
        {selectedTab === 3 && (
          <Box sx={{height:'97%'}}>
            <VolumeTab 
              selectedTicker={selectedTicker} 
              selectedDate={selectedDate} 
              handleSetDate={handleSetDate} 
              handleSetPrice={handleSetPrice}
              price={dailyPrice}
            />
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

