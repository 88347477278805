import { Grid, IconButton, TextField } from "@mui/material";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import RestartAltIcon from '@mui/icons-material/RestartAlt';

export default function PriceManager({price, handleSetPrice}){
    // eslint-disable-next-line
    const debouncedSetPrice = useCallback(
        debounce((value) => {
            return handleSetPrice(value);
        }, 1000),
        [handleSetPrice]
    );

    const [inputValue, setInputValue] = useState(price);

    const handleDebouncedSetPrice = useCallback((event) => {
        const newValue = event.target.value;
        setInputValue(newValue);
        return debouncedSetPrice(newValue);  
    }, [debouncedSetPrice]);

    const handleResetPrice = useCallback(() => {
        handleSetPrice(null);
    }, [handleSetPrice]);

    useEffect(() => {
        setInputValue(price);
    }, [price]);

    return (
        <Grid container
            justifyContent="flex-end" 
        >
            <Grid item>
                <TextField
                    variant='outlined'
                    size='small'
                    value={inputValue}
                    onChange={handleDebouncedSetPrice}
                    sx={{
                        width: '80px',
                        //backgroundColor: 'red',
                        '& .MuiOutlinedInput-input': {
                            textAlign: 'right',  
                        },
                    }}
                />
                <IconButton
                    onClick={handleResetPrice}  
                    aria-label="reset"
                >
                    <RestartAltIcon/>
                </IconButton>
            </Grid>
        </Grid>
    );
}