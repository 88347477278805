import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import dayjs from 'dayjs';
import { memo, useCallback, useEffect } from 'react';
import DailyMeta from './daily/DailyMeta';

const DateManager = memo(({date, prevDate, nextDate, minDate, handleSetDate, ...props}) => {

    const dayjsDate = date === 'latest' ? dayjs(DailyMeta.getInstance().getLatest()) : dayjs(date);    

    const handleNextDate = useCallback(() => {
        if (nextDate){
            handleSetDate(nextDate);
        }
        // else{
        //     const newDate = dayjsDate.add(1, 'day');
        //     if (newDate <= dayjs())
        //         handleSetDate(newDate.format('YYYYMMDD'));
        // }
    }, [handleSetDate, nextDate]);

    const handlePrevDate = useCallback(() => {
        if (prevDate){
            handleSetDate(prevDate);
        } 
        // else{
        //     const newDate = dayjsDate.subtract(1, 'day');
        //     if (newDate >= dayjs(minDate))
        //         handleSetDate(newDate.format('YYYYMMDD'));
        // }
    }, [handleSetDate, prevDate]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowLeft') {
                handlePrevDate();
            } else if (event.key === 'ArrowRight') {
                handleNextDate();
            }
        };


        if (process.env.REACT_APP_DAILY_PRICE_FEATURE !== 'true')
            document.body.addEventListener('keydown', handleKeyDown);

        return () => {
            document.body.removeEventListener('keydown', handleKeyDown);
        };
    }, [handlePrevDate, handleNextDate, handleSetDate]);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container 
                spacing={1} 
                alignItems="center" 
                justifyContent="flex-end" 
                {...props}
            >
                <Grid item>
                    <IconButton aria-label="previous date"  
                        onClick={handlePrevDate}
                        disabled={!prevDate}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                </Grid>
                <Grid item>
                    <IconButton aria-label="next date" 
                        onClick={handleNextDate}
                        disabled={!nextDate}
                    >
                        <ArrowForwardIcon />
                    </IconButton>
                </Grid>
                <Grid item>
                    <DatePicker
                        value={dayjsDate}
                        onChange={(newValue) => handleSetDate(newValue.format('YYYYMMDD'))}   
                        minDate={dayjs(minDate)}                     
                        maxDate={dayjs()} 
                        format="DD/MM/YYYY"
                        slotProps={{ textField: { size: 'small', style: {width : '150px' }} }}
                    />
                </Grid>
            </Grid>
        </LocalizationProvider>
    );
})

export default DateManager;