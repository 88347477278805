import { Box } from "@mui/material";
import SynchedChart from "../SynchedChart";
import { forwardRef, memo } from "react";
import { SAVE_KEY_MARKET_BREADTH_DATE_INTERVAL } from "../../util/Constants";


const MarketBreadthSecondaryChart = memo(forwardRef(({ sharedOption, startDate, endDate, data, ...props }, ref) => {    

    //console.log('m breadth data: ', data);
    const startValue = data['Date'].findIndex((date) => date === startDate) !== -1 ? data['Date'].findIndex((date) => date === startDate) : data['Date'].length - 500;
    const endValue = data['Date'].findIndex((date) => date === endDate) !== -1 ? data['Date'].findIndex((date) => date === endDate) : data['Date'].length - 1;
    const numDataPoints = endValue - startValue;

    const option = {  
        ...sharedOption, 
        grid: {
            left: '10px', //'4%',
            right: '38px', //'12%', 38
            bottom: '25px',//'20%',
            top: '30px',//'25%' 
            containLabel: true,
        },
        legend: {
            data: Object.keys(data).filter(key => key !== 'Date' && key !== 'Price'),
            icon: 'circle',
            top: '8px',//'6%',
            left: 'center',
        },
        xAxis: 
        {
            type: 'category',
            data: data['Date'],
            boundaryGap: true,
            axisPointer: {
                type: "shadow",
                show: true,
                label: {show: true},
            },    
            axisTick: {
                show: false
            },
            axisLabel: {
                maxLabels: 80,
                interval: Math.round(numDataPoints / 80) - 1,
                rotate: 30,
                fontSize: 6,
            },

        },
        yAxis: [
            {
                position: 'left',
                type: 'value',
                splitLine: {
                    show: false,
                },
                max: 100,
                axisLabel: {
                    formatter: function (value) {
                        //console.log(value);
                        if (value && value % 1000000 === 0)
                            return (value / 1000000) + 'M';
                        else if (value && value && value % 1000 === 0)
                            return (value / 1000) + 'K';
                        else
                            return value;
                    },
                    fontSize: 8,
                },                
            },
        ], 
        series: Object.keys(data).filter(key => key !== 'Date' && key !== 'Price').map(key => ({
            name: key,
            data: data[key],
            type: 'line',
            stack: 'Breadth', 
            areaStyle: {},
            symbol: 'none',
            smooth: true,
       })),   
        dataZoom: [
            {
                ...sharedOption.dataZoom[0],                
                startValue,
                endValue,
            }
        ],
    };  
    
    //console.log('series: ', option.series);
    // const sortOrder = ['Positives', 'Neutrals', 'Negatives'];
    // option.series.sort((a, b) => {
    //     return sortOrder.indexOf(a.name) - sortOrder.indexOf(b.name);
    // });
    // //const colors = createDistinctColors(3);    
    // option.series[0].color = '#748ddd';//blue
    // option.series[1].color = '#a9df96';//orange    
    // option.series[2].color = '#f6868a';//red


    return (
        <Box 
            width='100%' 
            height='100%' 
            border='1px solid #ccc'
        >
           <SynchedChart
                {...props}
                option={option}
                saveKey={SAVE_KEY_MARKET_BREADTH_DATE_INTERVAL}
                ref={ref}
            />
        </Box>
    );
}));

export default MarketBreadthSecondaryChart;