import { Box } from "@mui/material";
import StandardChart from "../StandardChart";

export default function OverviewDeltaChart({data}){

    const seriesName = Object.keys(data).filter((key) => key !== 'period');
    const option = {
        grid: {
            left: '10px',//'4%',
            right: '10px', //'4%',
            bottom: '10px',//'10%',
            top: '40px',//25
            containLabel: true,
        },
        tooltip: {
            trigger: 'item',
            position: function (point, params, dom, rect, size) {
                const xOffset = 10;
                let chartWidth = size.viewSize[0];
                let chartHeight = size.viewSize[1];                    

                let x = point[0];
                let y = point[1];

                if (x + xOffset + dom.offsetWidth > chartWidth)
                    x -= xOffset + dom.offsetWidth;
                else
                    x += xOffset;

                if (y - dom.offsetHeight / 2 >= 0 && y + dom.offsetHeight / 2 <= chartHeight)
                    y -= dom.offsetHeight / 2;
                else 
                    y -= dom.offsetHeight;
                // else if (y - dom.offsetHeight >= 0)
                //     y -= dom.offsetHeight;

                return [x, y];
            },
        },
        legend: {
            data: seriesName,
            icon: 'rectangle',
            top: '23px',//15
            left: 'center',
        },
        xAxis:
        {
            type: 'category',
            data: data['period'],
            boundaryGap: true,
            axisPointer: {
                type: "shadow",
                show: true,
                //label: { show: true },
            },
            axisTick: {
                show: false
            },
        },
        yAxis: [
            {
                position: 'left',
                //offset: 10,
                type: 'value',
                splitLine: {
                    show: false,
                },
            },
        ],
        series: seriesName.map((key) => ({
            name: key,
            data: data[key],
            type: 'bar',
            stack: 'overview',
            // emphasis: {
            //     focus: 'series'
            // }, 
            barGap: 0,           
            //large: true,  
        })),
    };

    return (
        <Box width='100%' height='100%' border='1px solid #ccc'>
           <StandardChart
                option={option}
            />
        </Box>
    );
}