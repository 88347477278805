import { Grid } from '@mui/material';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import MarketBreadthMainChart from '../components/market_breadth/MarketBreadthMainChart';
import SynchedChartContainer from '../components/SynchedChartContainer';
import { useDateInterval, useDispatchActionToCharts } from '../components/SynchedChartUtils';
import MarketBreadthSecondaryChart from '../components/market_breadth/MarketBreadthSecondaryChart';
import { fetchMarketBreadthChart, getUserMeta, setUserMeta } from '../util/http';
import { useQuery } from '@tanstack/react-query';
import Loading from '../components/Loading';
import Error from '../components/Error';
import { SAVE_KEY_MARKET_BREADTH_DATE_INTERVAL } from '../util/Constants';

const MarketBreadthPage = () => {

  const chart1 = useRef();
  const chart2 = useRef();
  const chartRefs = useMemo(() => [chart1, chart2], [chart1, chart2]);

  const dispatchActionToCharts = useDispatchActionToCharts(chartRefs);

  const memoizedDispacthFn0 = useCallback((action, payload) => dispatchActionToCharts(action, payload, chart1), [dispatchActionToCharts]);
  const memoizedDispacthFn1 = useCallback((action, payload) => dispatchActionToCharts(action, payload, chart2), [dispatchActionToCharts]);

  const [isFullscreen, setFullscreen] = useState([false, false]);
  
  const saveMarketInterval = useCallback((dateInterval) => {
    setUserMeta(SAVE_KEY_MARKET_BREADTH_DATE_INTERVAL, dateInterval);
},[]);


  const {data: { mainChartData = {}, secondaryChartData = {} } = {}, isPending, isError, error } = useQuery({
    queryKey: ['marketBreadth'],
    queryFn: () => fetchMarketBreadthChart(null, null),
    select: (data) => {
      //data.data.Date = data.data.Date.map((date) => `${date.slice(0, 4)}-${date.slice(4, 6)}-${date.slice(6, 8)}`);
      const mainChartData = {
        Date: data.data.Date, 
        Negatives: data.data.negatives, 
        Neutrals: data.data.neutrals, 
        Positives: data.data.positives,
        Price: data.data.SPX,
      };
      const secondaryChartData = {
        Date: data.data.Date, 
        Delta: data.data.delta
      };
      return {mainChartData, secondaryChartData};
    },
  });

  const { data: marketDateInterval, isPending: isMarketDatePending, isError: isMarketDateError, error: marketDateError } = useQuery({
    //queryKey: [SAVE_KEY_MARKET_BREADTH_DATE_INTERVAL, ...isFullscreen],
    queryKey: ['user_meta'],
    queryFn: () => getUserMeta(),
    select: (data) => data ? data[SAVE_KEY_MARKET_BREADTH_DATE_INTERVAL] : null,
    //initialData: defaultDateInterval,
});


const {startDate, endDate} = useDateInterval(marketDateInterval);

  const sharedOption = useMemo(() => ({
    animation: false,
    tooltip: {
        trigger: 'axis',
        position: function (point, params, dom, rect, size) {
            const xOffset = 10;
            var chartWidth = size.viewSize[0];
            var chartHeight = size.viewSize[1];
            let x = point[0];
            let y = point[1];

            if (x + xOffset + dom.offsetWidth > chartWidth)
                x -= xOffset + dom.offsetWidth;
            else
                x += xOffset;

            if (y - dom.offsetHeight / 2 >= 0)
                y -= dom.offsetHeight / 2;

            if (y + dom.offsetHeight > chartHeight)
                y -= dom.offsetHeight;

            return [x, y];
        },       
    },
    dataZoom: [
        {
            type: "inside",
            xAxisIndex: 0,
            maxValueSpan: 2500,
            minValueSpan: 100,
        },
    ],
  }), []);  

  if (isPending || isMarketDatePending)
    return <Loading/>;

  if (isError)
    return <Error error={error} />;

  if (isMarketDateError)
    return <Error error={marketDateError} />;


  return (
    <Grid container >            
            <Grid item sx={{ height:'75vh', width: '100%' }}>
              <SynchedChartContainer
                ChartComponent={MarketBreadthMainChart}
                data={mainChartData}
                ref={chartRefs[0]}
                dispatchFn={memoizedDispacthFn0}
                sharedOption={sharedOption}
                //ticker={selectedTicker}
                //settingsConfig={HISTORIC_MAIN_CHART_SETTINGS_CONFIG}
                //metrics={metrics.main.metrics}
                //type={metrics.main.type}
                startDate={startDate}
                endDate={endDate}
                fullscreen={isFullscreen[0]}
                handleFullscreenChart={(value) => setFullscreen([value, false])}
                handleSaveInterval={saveMarketInterval}
                //handleMetricsChanged={(selectedMetrics) => handleMetricsChanged(selectedMetrics, 'main')}
              />
            </Grid>
            <Grid item sx={{ height:'25vh', width: '100%' }}>
              <SynchedChartContainer
                ChartComponent={MarketBreadthSecondaryChart}
                data={secondaryChartData}
                ref={chartRefs[1]}
                dispatchFn={memoizedDispacthFn1}
                sharedOption={sharedOption}
                //ticker={selectedTicker}
                //settingsConfig={HISTORIC_MAIN_CHART_SETTINGS_CONFIG}
                //metrics={metrics.main.metrics}
                //type={metrics.main.type}
                startDate={startDate}
                endDate={endDate}
                fullscreen={isFullscreen[1]}
                handleFullscreenChart={(value) => setFullscreen([false, value])}
                handleSaveInterval={saveMarketInterval}
                //handleMetricsChanged={(selectedMetrics) => handleMetricsChanged(selectedMetrics, 'main')}
              />
            </Grid>            
    </Grid>
  );
};

export default MarketBreadthPage;
