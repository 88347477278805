//import echarts from 'echarts';

const ChartActions = {    
    TOOLTIP_ON_ENTER: 0,
    TOOLTIP_MOVE: 1,
    TOOLTIP_ON_LEAVE: 2,
    SET_DATA_ZOOM: 3,
    DATA_ZOOM: 4,
};

export { ChartActions };

const myTheme = {
    title: {
        textStyle: {
            color: 'white',
            fontFamily: 'Roboto',
            fontSize: 12,
        },
    },
    tooltip: {  
        transitionDuration: 0,
        hideDelay: 0,        
        //renderMode: "richText",
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        textStyle: {
            //color: '#fff', 
            color: 'white',
            fontFamily: 'Roboto',
        },            
    },
    legend: {
        textStyle: {
            //color: '#fff', 
            color: 'white',
            fontFamily: 'Roboto',
            fontSize: 8,            
        },  
        itemHeight: 8,
    },  
};

export { myTheme };

const EChartTheme_old = {
    "color": [
        "#5470c6",
        "#91cc75",
        "#fac858",
        "#ee6666",
        "#73c0de",
        "#3ba272",
        "#ec5618",
        "#9a60b4",
        "#ea7ccc"
    ],
    "backgroundColor": "#2a073b",
    "textStyle": {},
    "title": {
        "textStyle": {
            "color": "#e7e4cc"
        },
        "subtextStyle": {
            "color": "#b9bbc6"
        }
    },
    "line": {
        "itemStyle": {
            "borderWidth": 1
        },
        "lineStyle": {
            "width": 2
        },
        "symbolSize": 0,
        "symbol": "none",
        "smooth": false,
        showAllSymbol: false,
    },
    "radar": {
        "itemStyle": {
            "borderWidth": 1
        },
        "lineStyle": {
            "width": 2
        },
        "symbolSize": 4,
        "symbol": "emptyCircle",
        "smooth": false
    },
    "bar": {
        "itemStyle": {
            "barBorderWidth": 0,
            "barBorderColor": "#ffffff"
        }
    },
    "pie": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ffffff"
        }
    },
    "scatter": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ffffff"
        }
    },
    "boxplot": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ffffff"
        }
    },
    "parallel": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ffffff"
        }
    },
    "sankey": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ffffff"
        }
    },
    "funnel": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ffffff"
        }
    },
    "gauge": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ffffff"
        }
    },
    "candlestick": {
        "itemStyle": {
            "color": "#f80606",
            "color0": "#39eb0f",
            "borderColor": "#8d0f0f",
            "borderColor0": "#47b262",
            "borderWidth": "0"
        }
    },
    "graph": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ffffff"
        },
        "lineStyle": {
            "width": 1,
            "color": "#aaa"
        },
        "symbolSize": 4,
        "symbol": "emptyCircle",
        "smooth": false,
        "color": [
            "#5470c6",
            "#91cc75",
            "#fac858",
            "#ee6666",
            "#73c0de",
            "#3ba272",
            "#ec5618",
            "#9a60b4",
            "#ea7ccc"
        ],
        "label": {
            "color": "#ffffff"
        }
    },
    "map": {
        "itemStyle": {
            "areaColor": "#eee",
            "borderColor": "#444",
            "borderWidth": 0.5
        },
        "label": {
            "color": "#000"
        },
        "emphasis": {
            "itemStyle": {
                "areaColor": "rgba(255,215,0,0.8)",
                "borderColor": "#444",
                "borderWidth": 1
            },
            "label": {
                "color": "rgb(100,0,0)"
            }
        }
    },
    "geo": {
        "itemStyle": {
            "areaColor": "#eee",
            "borderColor": "#444",
            "borderWidth": 0.5
        },
        "label": {
            "color": "#000"
        },
        "emphasis": {
            "itemStyle": {
                "areaColor": "rgba(255,215,0,0.8)",
                "borderColor": "#444",
                "borderWidth": 1
            },
            "label": {
                "color": "rgb(100,0,0)"
            }
        }
    },
    "categoryAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "rgba(213,214,219,0.71)"
            }
        },
        "axisTick": {
            "show": true,
            "lineStyle": {
                "color": "#6E7079"
            }
        },
        "axisLabel": {
            "show": true,
            "color": "#6E7079"
        },
        "splitLine": {
            "show": false,
            "lineStyle": {
                "color": [
                    "#E0E6F1"
                ]
            }
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": [
                    "rgba(250,250,250,0.2)",
                    "rgba(210,219,238,0.2)"
                ]
            }
        }
    },
    "valueAxis": {
        "axisLine": {
            "show": false,
            "lineStyle": {
                "color": "#6E7079"
            }
        },
        "axisTick": {
            "show": false,
            "lineStyle": {
                "color": "#6E7079"
            }
        },
        "axisLabel": {
            "show": true,
            "color": "#6E7079"
        },
        "splitLine": {
            "show": true,
            "lineStyle": {
                "color": [
                    "#E0E6F1"
                ]
            }
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": [
                    "rgba(250,250,250,0.2)",
                    "rgba(210,219,238,0.2)"
                ]
            }
        }
    },
    "logAxis": {
        "axisLine": {
            "show": false,
            "lineStyle": {
                "color": "#6E7079"
            }
        },
        "axisTick": {
            "show": false,
            "lineStyle": {
                "color": "#6E7079"
            }
        },
        "axisLabel": {
            "show": true,
            "color": "#6E7079"
        },
        "splitLine": {
            "show": true,
            "lineStyle": {
                "color": [
                    "#E0E6F1"
                ]
            }
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": [
                    "rgba(250,250,250,0.2)",
                    "rgba(210,219,238,0.2)"
                ]
            }
        }
    },
    "timeAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "#6E7079"
            }
        },
        "axisTick": {
            "show": true,
            "lineStyle": {
                "color": "#6E7079"
            }
        },
        "axisLabel": {
            "show": true,
            "color": "#6E7079"
        },
        "splitLine": {
            "show": false,
            "lineStyle": {
                "color": [
                    "#E0E6F1"
                ]
            }
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": [
                    "rgba(250,250,250,0.2)",
                    "rgba(210,219,238,0.2)"
                ]
            }
        }
    },
    "toolbox": {
        "iconStyle": {
            "borderColor": "#999"
        },
        "emphasis": {
            "iconStyle": {
                "borderColor": "#666"
            }
        }
    },
    "legend": {
        "textStyle": {
            "color": "#ffffff"
        }
    },
    tooltip: {
        axisPointer: {
            lineStyle: {
                color: "#e7e5e5",
                width: "2"
            },
            crossStyle: {
                color: "#e7e5e5",
                width: 2,
            },
        },        
        trigger: "axis",
        transitionDuration: 0,
        hideDelay: 0,        
        //renderMode: "richText",
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        textStyle: {
            color: '#fff', 
            fontFamily: 'Roboto',
        },
    },
    "timeline": {
        "lineStyle": {
            "color": "#DAE1F5",
            "width": 2
        },
        "itemStyle": {
            "color": "#A4B1D7",
            "borderWidth": 1
        },
        "controlStyle": {
            "color": "#A4B1D7",
            "borderColor": "#A4B1D7",
            "borderWidth": 1
        },
        "checkpointStyle": {
            "color": "#316bf3",
            "borderColor": "fff"
        },
        "label": {
            "color": "#A4B1D7"
        },
        "emphasis": {
            "itemStyle": {
                "color": "#FFF"
            },
            "controlStyle": {
                "color": "#A4B1D7",
                "borderColor": "#A4B1D7",
                "borderWidth": 1
            },
            "label": {
                "color": "#A4B1D7"
            }
        }
    },
    "visualMap": {
        "color": [
            "#bf444c",
            "#d88273",
            "#f6efa6"
        ]
    },
    "dataZoom": {
        "handleSize": "undefined%",
        "textStyle": {}
    },
    "markPoint": {
        "label": {
            "color": "#ffffff"
        },
        "emphasis": {
            "label": {
                "color": "#ffffff"
            }
        }
    }
};

export default EChartTheme_old;