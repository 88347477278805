import DailyTab from './DailyTab';
import DailyMeta from './DailyMeta';

export default function VolumeTab({selectedTicker, selectedDate, handleSetDate, price, handleSetPrice}){    
    return (
        <DailyTab 
            selectedTicker={selectedTicker}
            charts={DailyMeta.getInstance().getCharts().slice(6)}
            selectedDate={selectedDate}
            handleSetDate={handleSetDate}
            handleSetPrice={handleSetPrice}
            price={price}
        />
    );
}