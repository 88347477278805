import { forwardRef, memo } from "react";

import EChartsReactCore from "echarts-for-react/lib/core";
import * as echarts from 'echarts/core';
import { LineChart, BarChart, CandlestickChart } from 'echarts/charts';
import { GridComponent, TitleComponent, TooltipComponent, DataZoomComponent, LegendComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

import { myTheme } from "./EChartTheme";
import { Paper } from "@mui/material";
import ChartFullscreenDialog from "./ChartFullscreenDialog";
import onChartReady from "./SynchedChartUtils";
//import ApacheChart from "./ApacheChart";

echarts.use([TitleComponent, TooltipComponent, GridComponent, LineChart, BarChart, CandlestickChart, CanvasRenderer, DataZoomComponent, LegendComponent]);
//echarts.registerTheme('BrightByte', myTheme);

const SynchedChart = memo(forwardRef(({ dispatchFn, sharedOption, handleSaveInterval, fullscreen, handleCloseFullscreen, handleFullsceenSettings, ...props }, ref) => {

    const option = {
        ...props.option, 
        ...sharedOption, 
    };

    
    if (fullscreen){        
        return (
            <ChartFullscreenDialog
                open={fullscreen}
                option={option}
                handleClose={handleCloseFullscreen}
                handleSettings={handleFullsceenSettings}
                onChartReady={(echartsInstance) => onChartReady(echartsInstance, null, handleSaveInterval)}
            />
        );
    }
    return (
        <Paper sx={{height:'100%'}}>
        <EChartsReactCore
            {...props}
            ref={ref}
            echarts={echarts}
            theme={myTheme}
            option={option}
            notMerge={true}
            lazyUpdate={true}
            style={{height: '100%'}}
            //style={props.style ? { ...props.style } : undefined}            
            onChartReady={(echartsInstance) => onChartReady(echartsInstance, dispatchFn, handleSaveInterval)}
        />
        </Paper>

    );
}));

export default SynchedChart;