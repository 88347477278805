import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormGroup, FormControlLabel, Grid, Box, Typography, RadioGroup, Radio, FormControl } from '@mui/material';
import { memo, useState } from 'react';

const SettingsDialog = memo(({open, handleCancel, handleSubmit, config, checkboxOptions, radioOption}) => {
    const [selectedCheckboxes, setSelectedCheckboxes] = useState(checkboxOptions ? checkboxOptions : []);
    const [selectedRadio, setSelectedRadio] = useState(radioOption ? radioOption : '');

    const handleCheckboxChange = (event) => {
        const value = event.target.value;
        setSelectedCheckboxes((prevSelected) => {            
            if (prevSelected.includes(value)){
                return prevSelected.filter((id) => id !== value);
            }
            else{
                return [...prevSelected, value];
            }
        });
    };

    const handleRadioChange = (event, newValue) => {
        setSelectedRadio(newValue);
    };    

    return (
            <Dialog open={open} onClose={handleCancel} style={{zIndex:10000000}}>
                <DialogTitle align="center">{config.title}</DialogTitle>
                <DialogContent>
                    {config.checkboxes &&
                        <Box mb={2} p={1} border="1px solid #ccc">
                            <Typography variant="subtitle1" align="center">{config.checkboxes.name}</Typography>
                            <FormGroup>
                                <Grid container paddingY={0} paddingX={1} rowSpacing={0} columnSpacing={config.spacing}>
                                    {config.checkboxes.options.map((option, index) => {
                                    return (
                                        <Grid item 
                                            key={index} 
                                            xs={6} 
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox 
                                                    sx={{ paddingY: 0 }}
                                                        checked={selectedCheckboxes.includes(option.id)} 
                                                        onChange={handleCheckboxChange} 
                                                        value={option.id} />
                                                }
                                                label={option.label}
                                            />
                                        </Grid>
                                    )})}
                                </Grid>
                            </FormGroup>
                        </Box>
                    }
                    {config.radio &&
                        <Box mb={2} p={1} border="1px solid #ccc">
                            <Typography variant="subtitle1" align="center">{config.radio.name}</Typography>
                            <FormGroup>
                                <Grid container paddingY={0} paddingX={1} rowSpacing={0} columnSpacing={config.spacing}>
                                    {config.radio.options.map((option, index) => {
                                        return (
                                            <Grid item xs={6} key={index}>
                                                <FormControl component="fieldset">
                                                    <RadioGroup value={selectedRadio} onChange={handleRadioChange}>
                                                        <FormControlLabel value={option.id} control={<Radio />} label={option.label} />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </FormGroup> 
                        </Box>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary">Cancel</Button>
                    <Box sx={{ flexGrow: 1 }} />
                    <Button onClick={() => handleSubmit({
                        selectedCheckboxes,
                        selectedRadio
                    })} variant="contained" color="primary">OK</Button>            </DialogActions>
            </Dialog>
        );            
});

export default SettingsDialog;