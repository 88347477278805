import React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { Typography } from '@mui/material';

export default function Error({ error })
{
  return (
    <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100%"
    >
        <Alert severity="error" >
            <AlertTitle >
                <Typography variant="h6">Error!</Typography>
            </AlertTitle>
            <Typography mt={2} variant="body1">
                {error.message || 'An unexpected error occurred.'}
            </Typography>
            {error.code && (
                <Typography mt={1} variant="body2">
                Status Code: {error.code}
                </Typography>
            )}
            {error.info && (
                <Typography variant="body2">
                Additional Information: {JSON.stringify(error.info)}
                </Typography>
            )}
        </Alert>
    </Box>
    );
}

