import { memo } from "react";
import { Dialog, DialogActions, DialogContent, IconButton } from "@mui/material";
import { FullscreenExit, Settings } from "@mui/icons-material";
import FullscreenChart from "./FullscreenChart";

const ChartFullscreenDialog = memo(({ open, option, handleClose, handleSettings, onChartReady }) => {
    return (
        <Dialog 
            open={open} 
            onClose={handleClose} 
            fullScreen
            style={{zIndex:10000000}}
            >
            <DialogActions style={{ position: 'absolute', top: 0, right: 0, zIndex: 1000  }}>
                {handleSettings && 
                    <IconButton onClick={(event) => {event.preventDefault(); event.stopPropagation(); handleSettings();}}>
                        <Settings />
                    </IconButton >
                }
                <IconButton onClick={handleClose}>
                    <FullscreenExit />
                </IconButton >
            </DialogActions>
            <DialogContent>
                <FullscreenChart
                    option={option}
                    onChartReady={onChartReady}
                />
            </DialogContent>
        </Dialog>
    );
});

export default ChartFullscreenDialog;