import './App.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { CssBaseline } from '@mui/material';
import LayoutContainer from './pages/LayoutContainer.jsx';

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        // Time to keep data in the cache after it becomes inactive
        cacheTime: 1000 * 60 * 10, // 10 minutes
      
        // Time to consider data fresh before needing to refetch
        staleTime: 1000 * 60 * 5, // 5 minutes
        refetchOnWindowFocus: false,
        retry: false,
        //retry: 1,
      },
    },    
  });

  //echarts.registerTheme('BrightByte', EChartTheme);

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    }, 
    typography: {
      body2: {
        fontSize: '0.7rem',
      }
    },
  });

  return (    
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={darkTheme}>
          <CssBaseline />
          <LayoutContainer />            
        </ThemeProvider>
      </QueryClientProvider>    
  );
}

export default App;
