import EChartsReactCore from "echarts-for-react/lib/core";
import * as echarts from 'echarts/core';
import { BarChart } from 'echarts/charts';
import { GridComponent, TitleComponent, TooltipComponent, DataZoomComponent, LegendComponent, MarkLineComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { Paper } from "@mui/material";
import { myTheme } from "./EChartTheme";
import { memo } from "react";

echarts.use([TitleComponent, TooltipComponent, GridComponent, BarChart, CanvasRenderer, DataZoomComponent, LegendComponent, MarkLineComponent]);
echarts.registerTheme('BrightByte', myTheme);

const StandardChart = memo(({option}) => {

    return (
        <Paper sx={{height:'100%'}}>
            <EChartsReactCore
                echarts={echarts}
                style={{height: '100%'}}
                theme={myTheme}
                option={option}
                notMerge={true}
                lazyUpdate={true}
            />
        </Paper>
    );
});

export default StandardChart;