import { Box, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";

export default function OverviewTable({title, data, colorHandle}){
    
    return (
        <Box border="1px solid #ccc" style={{ height: '100%', width: '100%', maxHeight: '45vh', overflowY:'auto', overflowX: 'hidden'}}>
            <Paper sx={{height:'100%', padding: '10px'}}>
                <Typography variant="h6" textAlign='center' sx={{marginBottom: '10px'}}>{title}</Typography>            
                <Table sx={{margin:'0px !important'}}>
                    <TableHead style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor:  'black' }}>
                        <TableRow
                            key='header'
                            sx={{ '& > *': { padding: '2px !important', minHeight: '100px !important', textTransform: 'uppercase'} }}                        
                        >
                            {data && data.header.map((columnHeader, index) => (
                                <TableCell key={`header-cell-${index}`} align='center' sx={{ padding: 1, flexGrow: 1 }}/*sx={{minWidth: 24, maxWidth: 24, minHeight: '100px !important', maxHeight: 100}}*/><Typography fontWeight='bold' variant="body1">{columnHeader}</Typography></TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data && data.body.map((rowData, rowIndex) => (
                        <TableRow key={`row-${rowIndex}`}>
                            {rowData && rowData.map((cellData, cellIndex) => (
                                <TableCell 
                                    key={`row-${rowIndex}-cell-${cellIndex}`} 
                                    align='center' 
                                    sx={{ padding: 1, flexGrow: 1 }} 
                                >
                                    <Typography 
                                        color={() => colorHandle && colorHandle(rowData, cellIndex)}
                                        variant="body1"
                                    >
                                        {cellData}
                                    </Typography>
                                </TableCell>
                            ))}                                            
                        </TableRow>
                    ))}
                    </TableBody>                                     
                </Table>
            </Paper>
        </Box>

    );
}