import { convertServerDateToJs } from "../../util/Util";

export default class HistoricMeta {
    static instance = null;
  
    static getInstance() {
      if (!HistoricMeta.instance) {
        HistoricMeta.instance = new HistoricMeta();
      }
      return HistoricMeta.instance;
    }
  
    setData(data){
      this.data = data;
    }
    
    getMetricDescription(id)
    {
        if (id.startsWith('-')) {
            id = id.substring(1);
          }

        const metric = this.data.data.metrics.find((metric) => metric.metric === id);
        return metric.description;
    }

    getDeltaDescription(delta, metric_id)
    {
        const metric = this.data.data.forward_delta_metrics.find((metric) => metric.metric === metric_id);
        return delta + ' ' + metric.description;
    }

    getSince()
    {
      return this.data.data.since;
    }

    getLatest(){
      return this.data.metadata.latest;
    }

    getLatestJs(){
      if (!this.data)
          return '2024-08-05';
      return convertServerDateToJs(this.data.metadata.latest);
    }    
};