import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

export default function Loading()
{
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100%"
        >
            <CircularProgress color="primary" size={40} />
            <Typography variant="body1" mt={2}>
            Loading...
            </Typography>
        </Box>
    );
}
