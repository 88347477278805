export const HISTORIC_VOLUME_TYPE = {
    C_VOL_P_VOL: 'CVVSPV',
    C_VOL_DELTA_P_VOL_DELTA: 'CVDVSPVD',
    VOL_LO_VOL_HI: 'VLVSVH',
    VOL_LO_DELTA_VOL_HI_DELTA: 'VLDVSVHD',
};

export const HISTORIC_DELTA_TYPE = {
    C_DELTA_P_DELTA: 'CDVSPD',
    LO_DELTA_HI_DELTA : 'LDVSHD',
    ITM_C_DELTA_OTM_C_DELTA : 'ITMOTM',
};

export const HISTORIC_MAIN_CHART_SETTINGS_CONFIG = {
    title: 'Main Chart',
    spacing: 29,
    checkboxes: {
        name: 'Indicators',
        options: [
            {
                id: 'pct_delta_diff',
                label: 'Δ DIFF %'
            },
            {
                id: 'positioninig_rating_v1',
                label: 'Rating V1'
            },
            {
                id: 'rank_v1',
                label: 'Rank V1'
            },
            {
                id: 'percentile_rank_v1',
                label: 'Percentile V1'
            },
            {
                id: 'positioninig_rating_v2',
                label: 'Rating V2'
            },
            {
                id: 'rank_v2',
                label: 'Rank V2'
            },
            {
                id: 'percentile_rank_v2',
                label: 'Percentile V2'
            },
            {
                id: 'avg_atm_iv',
                label: 'AVG ATM IV'
            },
            {
                id: 'put_oi',
                label: 'PUT OI'
            },
            {
                id: 'call_oi',
                label: 'CALL OI'
            },
            {
                id: 'p_c_oi',
                label: 'P/C OI'
            },
            {
                id: 'fw0d',
                label: 'FW Δ 0'
            },
            {
                id: 'fw1d',
                label: 'FW Δ 1'
            },
            {
                id: 'fw5d',
                label: 'FW Δ 5'
            },
            {
                id: 'fw8d',
                label: 'FW Δ 8'
            },
            {
                id: 'fw13d',
                label: 'FW Δ 13'
            },
            {
                id: 'fw21d',
                label: 'FW Δ 21'
            },
            {
                id: 'fw34d',
                label: 'FW Δ 34'
            },
            {
                id: 'fw55d',
                label: 'FW Δ 55'
            },
        ],
    },
    radio:
    {
        name: 'Settings',
        options: [
            {
                id: 'line',
                label: 'Line',
            },
            {
                id: 'candlestick',
                label: 'Candlestick',
            }
        ],
    }        
};

export const HISTORIC_VOLUME_CHART_SETTINGS_CONFIG = {
    title: 'Volume Chart',
    spacing: 2,
    radio: {
        name: 'Settings',
        options: [
            {
                id: HISTORIC_VOLUME_TYPE.C_VOL_P_VOL,
                label: 'Call Volume vs Put Volume',
            },
            {
                id: HISTORIC_VOLUME_TYPE.C_VOL_DELTA_P_VOL_DELTA,
                label: 'Call Vol Δ vs Put Vol Δ',
            },
            {
                id: HISTORIC_VOLUME_TYPE.VOL_LO_VOL_HI,
                label: 'Vol Lower vs Vol Higher',
            },
            {
                id: HISTORIC_VOLUME_TYPE.VOL_LO_DELTA_VOL_HI_DELTA,
                label: 'Vol Lower Δ vs Vol Higher Δ',
            },
        ],
    }
};

export const HISTORIC_DELTA_CHART_SETTINGS_CONFIG = {
    title: 'Delta Chart',
    spacing: 0,
    radio: {
        name: 'Settings',
        options: [
            {
                id: HISTORIC_DELTA_TYPE.C_DELTA_P_DELTA,
                label: 'Call Δ vs Put Δ',
            },
            {
                id: HISTORIC_DELTA_TYPE.LO_DELTA_HI_DELTA,
                label: 'Lower Δ vs Higher Δ',
            },
            {
                id: HISTORIC_DELTA_TYPE.ITM_C_DELTA_OTM_C_DELTA,
                label: 'ITM C OTM P OTM C ITM P Δ',
            },            
        ],
    }
};

export const HISTORIC_DELTA_METRICS = {
    [HISTORIC_DELTA_TYPE.C_DELTA_P_DELTA]: ['pct_call_delta', 'pct_put_delta'],
    [HISTORIC_DELTA_TYPE.LO_DELTA_HI_DELTA] : ['pct_l_delta', 'pct_h_delta'],
    [HISTORIC_DELTA_TYPE.ITM_C_DELTA_OTM_C_DELTA] : ['pct_itm_c_delta', 'pct_otm_p_delta', 'pct_otm_c_delta', 'pct_itm_p_delta'],
};

export const HISTORIC_VOLUME_METRICS = {
    [HISTORIC_VOLUME_TYPE.C_VOL_P_VOL] : ['call_vol', '-put_vol'],
    [HISTORIC_VOLUME_TYPE.C_VOL_DELTA_P_VOL_DELTA] : ['cv_delta', '-pv_delta'],
    [HISTORIC_VOLUME_TYPE.VOL_LO_VOL_HI] : ['h_vol', '-l_vol'],
    [HISTORIC_VOLUME_TYPE.VOL_LO_DELTA_VOL_HI_DELTA] : ['vl_delta', '-vh_delta'],
};
