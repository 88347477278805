import { Box, Paper, Typography } from "@mui/material";
import { memo } from "react";
import RangeMarkers from "../RangeMarkers";

const PositioningRating = memo(({data}) => {
    return (
        <Box width='100%' height="100%" border='1px solid #ccc'>
            <Paper sx={{height:'100%', padding: '10px'}}>
                <Typography variant="h6" textAlign='center' sx={{marginBottom: '3%'}}>Positioning Rating</Typography>            
                <RangeMarkers
                    title='TODAY'
                    markers={[data['Today']]}
                />
                <RangeMarkers
                    title='30 DAY RANGE'
                    markers={data['30 Day Range']}
                />
                <RangeMarkers
                    title='90 DAY RANGE'
                    markers={data['90 Day Range']}
                />
            </Paper>
        </Box>
    );
});

export default PositioningRating;