import { convertServerDateToJs } from "../../util/Util";

export default class DailyMeta {
    static instance = null;
  
    static getInstance() {
      if (!DailyMeta.instance) {
        DailyMeta.instance = new DailyMeta();
      }
      return DailyMeta.instance;
    }

    setData(data){
      this.data = data;
    }

    getCharts(){
      return this.data.data.charts;
    }

    getLatest(){
      return this.data.metadata.latest;
    }

    getLatestJs(){
      if (!this.data)
          return '2024-08-05';
      return convertServerDateToJs(this.data.metadata.latest);
    }

};